import Choices from 'choices.js'

document.addEventListener  'turbolinks:load', () ->
  elements = document.querySelectorAll 'select.select-choice'
  return false if(elements.length == 0)
  for el in elements
    new Choices el, {
      removeItemButton: true
      searchChoices: true
      searchEnabled: true
      loadingText: 'Loading...'
      noResultsText: 'No se encontraron resultados'
      noChoicesText: 'No se encontraron opciones'
      itemSelectText: 'Click para seleccionar'
      addItemText: (value) -> "Enter para agregar: <b>#{ value }</b>"
      maxItemText: (maxItemCount) -> "Solo #{ maxItemCount } pueden ser añadidos"
    }
